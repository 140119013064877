import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from './HomePage';
import SignUp from './SignUp';
import LogIn from './LogIn';
import { Amplify } from 'aws-amplify';
import awsconfig from './aws-exports';
import MyCertifications from './MyCertifications';
import AddCertification from './AddCertification';
import AddActivity from './AddActivity';
import UpdateActivity from './UpdateActivity';
import UpdateCertification from './UpdateCertification';
import AboutUs from './AboutUs';
import TermsAndConditions from './TermsAndConditions';
import ContactUs from './ContactUs';
import FourOFourPage from './FourOFourPage';

Amplify.configure(awsconfig);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<LogIn />} />
        <Route path="/mycertifications" element={<MyCertifications />} />
        <Route path="/addcertification" element={<AddCertification />} />
        <Route path="/addactivity" element={<AddActivity />} />
        <Route path="/updateactivity/:activityId" element={<UpdateActivity />} />
        <Route path="/addactivity/:certId" element={<AddActivity />} />
        <Route path="/updatecertification/:certification_id" element={<UpdateCertification />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/termsandconditions" element={<TermsAndConditions />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/fourofourpage" element={<FourOFourPage />} />
      </Routes>
    </Router>
  );
}

export default App;
