import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './UpdateActivity.css'; // Import the new CSS file
import { Auth } from 'aws-amplify';

function UpdateActivity() {
    const backendURL = "https://loc6yen3ke.execute-api.ap-southeast-2.amazonaws.com/prod";
    const { activityId } = useParams();
    const navigate = useNavigate();
    const [activity, setActivity] = useState({
        activity_id: "",
        certification_id: "",
        activity_name: "",
        description: "",
        cpe_units: "",
        activity_date: ""
    });

    useEffect(() => {
        async function fetchData() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const response = await fetch(`${backendURL}/api/activity/${activityId}`);
                const response2 = await fetch(`${backendURL}/api/users/${user.attributes.email}`);
                const data = await response.json();
                const data2 = await response2.json();
                const cert2 = data2.user_id;
                const cert = data[0];
                if (cert2 !== data.user_id) {
                    navigate('/fourofourpage');
                    return;
                }
                data.activity_date = formatDate(data.activity_date);
                setActivity(data);
            } catch (error) {
                navigate('/fourofourpage');
                console.error("Error fetching activity data:", error);
            }
        }

        fetchData();
    }, [activityId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setActivity(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const user = await Auth.currentAuthenticatedUser();
            const response3 = await fetch(`${backendURL}/api/activity/${activityId}`);
            const response2 = await fetch(`${backendURL}/api/users/${user.attributes.email}`);
            const data = await response3.json();
            const data2 = await response2.json();
            const cert2 = data2.user_id;
            const cert = data[0];
            if (cert2 !== data.user_id) {
                navigate('/fourofourpage');
                return;
            }

            if (activity.cpe_units === "") {
                activity.cpe_units = 0;
            }

            const response = await fetch(`${backendURL}/api/activities/${activityId}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(activity)
            });

            if (response.ok) {
                navigate('/MyCertifications');
            } else {
                console.error("Error updating activity:", await response.text());
            }
        } catch (error) {
            console.error("Error sending update request:", error);
        }
    };

    return (
        <div className="activity-update-container">
            <h2>Update Activity</h2>
            <p className="mandatory-note">All fields marked with * are mandatory</p>
            <form onSubmit={handleSubmit}>
                <div className="input-group">
                    <label>* Activity Name:</label>
                    <input type="text" name="activity_name" value={activity.activity_name} onChange={handleChange} />
                </div>
                <div className="input-group">
                    <label>Description:</label>
                    <textarea name="description" value={activity.description} onChange={handleChange}></textarea>
                </div>
                <div className="input-group">
                    <label>CPE Units:</label>
                    <input type="number" name="cpe_units" value={activity.cpe_units} onChange={handleChange} />
                </div>
                <div className="input-group">
                    <label>* Activity Date:</label>
                    <input type="date" name="activity_date" value={activity.activity_date} onChange={handleChange} />
                </div>
                <div className="button-container">
                    <button type="submit" className="update-button">Update</button>
                    <button type="button" className="cancel-button" onClick={() => navigate('/MyCertifications')}>Cancel</button>
                </div>
            </form>
        </div>
    );
}

function formatDate(isoString) {
    return isoString.split('T')[0];
}

export default UpdateActivity;
