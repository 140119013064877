import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import './AddActivity.css';

function AddActivity() {
    const { certId } = useParams();
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [cpeUnits, setCpeUnits] = useState('');
    const [activityDate, setActivityDate] = useState('');
    const backendURL = "https://loc6yen3ke.execute-api.ap-southeast-2.amazonaws.com/prod";
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate('/mycertifications');
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${backendURL}/api/activities`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    certification_id: certId,
                    activity_name: name,
                    description,
                    cpe_units: cpeUnits || 0,
                    activity_date: activityDate
                })
            });

            const result = await response.json();
            if (result && result.activity_id) {
                navigate('/mycertifications');  // Redirect back to certifications after adding
            }
        } catch (error) {
            console.error("Error adding activity:", error);
        }
    };

    return (
        <div className="add-activity">
            <h2>Add Activity</h2>
            <p className="mandatory-note">All fields marked with * are mandatory</p>
            <form onSubmit={handleSubmit}>
                <div>
                    <label>* Name: </label>
                    <input type="text" value={name} onChange={e => setName(e.target.value)} required />
                </div>
                <div>
                    <label>Description: </label>
                    <input type="text" value={description} onChange={e => setDescription(e.target.value)} />
                </div>
                <div>
                    <label>CPE Units: </label>
                    <input type="number" value={cpeUnits} onChange={e => setCpeUnits(e.target.value)} />
                </div>
                <div>
                    <label>* Date: </label>
                    <input type="date" value={activityDate} onChange={e => setActivityDate(e.target.value)} required />
                </div>
                <div className="action-buttons">
                    <button type="submit">Add</button>
                    <button type="button" onClick={handleCancel} className="cancel-button">Cancel</button>
                </div>
            </form>
        </div>
    );
}

export default AddActivity;
