import React from 'react';
import './FourOFourPage.css';

function FourOFourPage() {
  return (
    <div className="FourOFourPage">
        <div className="FourOFourPage__container">
            <h1 className="FourOFourPage__title">404</h1>
            <p className="FourOFourPage__message">Oops! This isn't the apple you were trying to pick. Let's find a juicier one together.</p>
            <a href="/" className="FourOFourPage__homeLink">Go Home</a>
        </div>
    </div>
  );
}

export default FourOFourPage;
