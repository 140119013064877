import React, { useState, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Auth, API } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import './MyCertifications.css';  // Import the CSS
import Footer from './Footer';

function MyCertifications() {
    const [certifications, setCertifications] = useState([]);
    const [showActivityModal, setShowActivityModal] = useState(false);
    const [showRecommendationModal, setShowRecommendationModal] = useState(false);
    const [activities, setActivities] = useState([]);
    const [selectedCert, setSelectedCert] = useState(null);
    const backendURL = "https://loc6yen3ke.execute-api.ap-southeast-2.amazonaws.com/prod";
    const navigate = useNavigate();
    const [recommendedCertifications, setRecommendedCertifications] = useState('');
    const [loading, setLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredCertifications, setFilteredCertifications] = useState([]);
    const [sortType, setSortType] = useState('');


    useEffect(() => {
        async function fetchCertifications() {
            try {
                const authUser = await Auth.currentAuthenticatedUser();
                const userEmail = authUser.attributes.email;
                console.log(userEmail)

                const userResponse = await fetch(`${backendURL}/api/users/${encodeURIComponent(userEmail)}`);
                const userData = await userResponse.json();

                if (userData.user_id) {
                  const certsResponse = await fetch(`${backendURL}/api/certifications/${userData.user_id}`);
                  console.log(certsResponse)
                  const certsData = await certsResponse.json();
                  setCertifications(certsData);
                } else {
                  console.warn("User not found in RDS.");
                }
            } catch (error) {
                navigate('/fourofourpage');
                console.error("Error fetching certifications:", error);
            }
        }

        fetchCertifications();
    }, [loading]);

    useEffect(() => {
        let sortedCerts = [...certifications];
        if (sortType === "expiryDate") {
            sortedCerts.sort((a, b) => {
                if (new Date(a.expiry_date).getTime() === new Date("1970-01-01").getTime() && new Date(b.expiry_date).getTime() === new Date("1970-01-01").getTime()) {
                    return 0;
                } else if (new Date(a.expiry_date).getTime() === new Date("1970-01-01").getTime()) {
                    return 1; // If a's expiry_date is N/A, it should come after b
                } else if (new Date(b.expiry_date).getTime() === new Date("1970-01-01").getTime()) {
                    return -1; // If b's expiry_date is N/A, it should come after a
                } else {
                    return new Date(a.expiry_date) - new Date(b.expiry_date);
                }
            });
        } else if (sortType === "name") {
            sortedCerts.sort((a, b) => a.certification_name.localeCompare(b.certification_name));
        } else if (sortType === "earnedDate") {
            // Assuming you have a property called earned_date in your certifications
            sortedCerts.sort((a, b) => new Date(b.earned_date) - new Date(a.earned_date));
        }
        setFilteredCertifications(sortedCerts);
    }, [certifications, sortType]);

    const deleteCertification = async (certId) => {
        const confirmation = window.confirm("Are you sure you want to delete this certification? This action is irreversible.");
        if (confirmation) {
            try {
                // Check if the certification has activities
                const activitiesResponse = await fetch(`${backendURL}/api/activities/${certId}`);
                const activitiesData = await activitiesResponse.json();

                if (activitiesData && activitiesData.length > 0) {
                    // If there are activities, show a warning
                    window.alert("There are currently activities in this certification. Please delete all activities before deleting this certification.");
                } else {
                    // Otherwise, proceed to delete the certification
                    await fetch(`${backendURL}/api/certifications/${certId}`, { method: 'DELETE' });
                    setCertifications(prevCerts => prevCerts.filter(cert => cert.certification_id !== certId));
                }
            } catch (error) {
                console.error(`Error deleting certification ${certId}:`, error);
            }
        }
    }

    const handleRenewCertification = async (certId, earnedCPEs, requiredCPEs) => {
        if (earnedCPEs < requiredCPEs) {
            window.alert("Insufficient CPEs to renew; please earn or input more before renewing this certification.");
            return;
        }

        const confirmation = window.confirm("Are you sure you would like to renew this certification?");
        if (confirmation) {
            setLoading(true);
            try {
                const response = await fetch(`${backendURL}/api/renewCertification/${certId}`, { method: 'PUT' });
                const updatedCert = await response.json();
                if (updatedCert) {
                    setCertifications(prevCerts => prevCerts.map(cert => cert.certification_id === certId ? updatedCert : cert));
                }
            } catch (error) {
                console.error(`Error renewing certification ${certId}:`, error);
            } finally {
                setLoading(false);
            }
        }
    }

    const handleUpdateCertification = (certId, certName) => {
        navigate(`/updatecertification/${certId}`, { state: { currentName: certName } });
    }

    const deleteActivity = async (activityId) => {
        const confirmation = window.confirm("Are you sure you want to delete this activity? This action is irreversible.");
        if (confirmation) {
            try {
                await fetch(`${backendURL}/api/activities/${activityId}`, { method: 'DELETE' });
                setActivities(prevActivities => prevActivities.filter(activity => activity.activity_id !== activityId));
            } catch (error) {
                console.error(`Error deleting activity ${activityId}:`, error);
            }
        }
    }

    const getCertificationRecommendations = async () => {
        try {
            const apiName = 'secretsAPI';
            const path = '/secrets/gpt3-key';
            const apiResponse = await API.get(apiName, path);
            const parsedResponse = JSON.parse(apiResponse);
            const newKey = parsedResponse.CHATGPT_API_KEY;
            const currentCertifications = certifications.map(cert => cert.certification_name);

            const messageContent = [
                {
                    role: "system",
                    content: "You are a helpful assistant that provides certification recommendations."
                },
                {
                    role: "user",
                    content: `
                        I have these certifications: ${currentCertifications}
                        Recommend me what other certifications to pursue.
                    `
                }
            ];

            const response = await fetch('https://api.openai.com/v1/chat/completions', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + newKey
                },
                body: JSON.stringify({ model: "gpt-3.5-turbo", messages: messageContent })
            });

            const data = await response.json();

            if (data.choices && data.choices[0] && data.choices[0].message && data.choices[0].message.content) {
                setRecommendedCertifications(data.choices[0].message.content.trim());
                setShowRecommendationModal(true);
            } else {
                console.error('Unexpected API response format');
            }
        } catch (error) {
            console.error("Error fetching certification recommendations:", error);
        }
    }

    const handleSearch = (e) => {
        setSearchQuery(e.target.value);
        if (e.target.value === "") {
            setFilteredCertifications(certifications);
        } else {
            const filtered = certifications.filter(cert => cert.certification_name.toLowerCase().includes(e.target.value.toLowerCase()));
            setFilteredCertifications(filtered);
        }
    }

    const handleViewTracking = async (certId) => {
        try {
            const response = await fetch(`${backendURL}/api/activities/${certId}`);
            const activitiesData = await response.json();
            setActivities(activitiesData);
            setSelectedCert(certId);
            setShowActivityModal(true);
        } catch (error) {
            console.error(`Error fetching activities for certification ${certId}:`, error);
        }
    }

    const handleClose = () => {
        setShowActivityModal(false);
        setShowRecommendationModal(false);
        setSelectedCert(null);
        setActivities([]);
    }

    const handleAddActivity = () => {
        navigate(`/addactivity/${selectedCert}`);  // Use navigate function
    }

    return (
      <div className="full-height-container">
          <div className="my-certifications-container">
            <Button className="back-to-home-btn" onClick={() => navigate("/")}>Back to Home Page</Button>
            <h2>My Certifications</h2>
            <Button className="add-cert-button" onClick={() => window.location.href = "/addcertification"}>Add Certification</Button>
            <Button className="recommend-cert-button" onClick={getCertificationRecommendations}>Recommend Certifications!</Button>

            <select
              className="sort-dropdown"
              value={sortType}
              onChange={(e) => setSortType(e.target.value)}
            >
              <option value="">Sort By</option>
              <option value="expiryDate">Sort By Expiry Date</option>
              <option value="name">Sort By Name</option>
              <option value="earnedDate">Sort By Earned Date</option>
            </select>

            <div className="search-container">
                <input
                    className="certification-search-bar"
                    type="text"
                    placeholder="Search Certifications..."
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>

            <div className="certifications-list">
                {filteredCertifications.length ? (
                    filteredCertifications.map(cert => (
                    <div key={cert.certification_id} className="certification-card">
                        <h4>{cert.certification_name}</h4>

                        <div className="details-and-progress">
                            <div className="certification-details">
                            {cert.cpe_needed && <p>CPEs Needed: {cert.cpe_needed}</p>}
                            {cert.total_cpes_earned && <p>CPEs Earned: {cert.total_cpes_earned}</p>}
                            {
                              cert.expiry_date ?
                              (new Date(cert.expiry_date).getTime() === new Date("1970-01-01").getTime() ?
                                <p>Expiry Date: N/A</p> :
                                <p>Expiry Date: {new Date(cert.expiry_date).toLocaleDateString()}</p>) :
                              null
                            }
                            {cert.verification_link && <p>Verification Link: <a href={cert.verification_link} target="_blank" rel="noopener noreferrer">{cert.verification_link}</a></p>}
                            </div>

                            {cert.cpe_needed && (
                                <div className="progress-container">
                                    <CircularProgressBar percentage={cert.percentage_completion} />
                                </div>
                            )}
                        </div>

                        <div className="certification-card-buttons">
                            <Button className="view-tracking-button" onClick={() => handleViewTracking(cert.certification_id)}>View Activities</Button>
                            <Button className="update-button" onClick={() => handleUpdateCertification(cert.certification_id, cert.certification_name)}>Update</Button>
                            <Button className="renew-button" onClick={() => handleRenewCertification(cert.certification_id, cert.total_cpes_earned, cert.cpe_needed)}>Renew</Button>
                            <Button className="delete-button" variant="danger" onClick={() => deleteCertification(cert.certification_id)}>Delete</Button>
                        </div>
                    </div>
                    ))
                ) : (
                    <p>You have no certifications right now. Add some in!</p>
                )}
            </div>

            <Modal show={showActivityModal} onHide={handleClose} className="activity-modal" size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Activities for {certifications.find(cert => cert.certification_id === selectedCert)?.certification_name}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="activity-modal-body">
                    {activities.length ? activities.map(activity => (
                        <div key={activity.activity_id} className="activity-card">
                            <h5>{activity.activity_name}</h5>
                            <p>{activity.description}</p>
                            <p>CPE Units: {activity.cpe_units}</p>
                            <p>Date: {new Date(activity.activity_date).toLocaleDateString()}</p>
                            {activity.is_archived && <p>This activity has been archived.</p>}
                            <div className="activity-card-buttons">
                                <div className="row">
                                    <div className="col-6">
                                        <Button className="update-button" onClick={() => navigate(`/updateactivity/${activity.activity_id}`)}>Update</Button>
                                    </div>
                                    <div className="col-6">
                                        <Button className="delete-button" variant="danger" onClick={() => deleteActivity(activity.activity_id)}>Delete</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )) : (
                        <p>No activities for this certification yet. Add some in!</p>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="add-activity-button" onClick={handleAddActivity}>Add Activity</Button>
                    <Button className="close-button" variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRecommendationModal} onHide={handleClose} className="recommendation-modal" size="md">
                <Modal.Header closeButton>
                    <Modal.Title>Recommended Certifications</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {recommendedCertifications}
                </Modal.Body>
                <Modal.Footer>
                    <Button className="close-button" variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
        <Footer />
      </div>
    );
}

function CircularProgressBar({ percentage = 0 }) {
    const radius = 50;
    const circumference = 2 * Math.PI * radius;
    const validPercentage = (typeof percentage === 'number') ? percentage : 0;
    const offset = circumference - (validPercentage / 100) * circumference;

    return (
      <svg height="120" width="120">
        <circle
          stroke="lightgray"
          fill="transparent"
          r={radius}
          cx="60"
          cy="60"
          strokeWidth="10"  // Updated strokeWidth to make it thicker
        />
        <circle
          stroke="blue"
          fill="transparent"
          r={radius}
          cx="60"
          cy="60"
          strokeWidth="10"  // Updated strokeWidth to make it thicker
          strokeDasharray={circumference}
          strokeDashoffset={offset}
        />
        <text x="60" y="60" fill="black" textAnchor="middle" dy=".3em">{percentage}%</text>
      </svg>
    );
  }

export default MyCertifications;
