import React, { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate, useLocation } from 'react-router-dom';
import './SignUp.css';

function SignUp() {
    const location = useLocation();
    const isUnconfirmedNavigation = location?.state?.unconfirmed;
    const userEmailFromLogin = location?.state?.userEmail;
    const [password, setPassword] = useState("");
    const [confirmationCode, setConfirmationCode] = useState("");
    const [error, setError] = useState("");
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const [acceptTerms, setAcceptTerms] = useState(false);
    const [email, setEmail] = useState(isUnconfirmedNavigation ? userEmailFromLogin : "");
    const [step, setStep] = useState(isUnconfirmedNavigation ? 1 : 0);
    const [marketingOpt, setMarketingOpt] = useState(false);
    const navigate = useNavigate();
    const backendURL = "https://loc6yen3ke.execute-api.ap-southeast-2.amazonaws.com/prod";

    useEffect(() => {
      if (isUnconfirmedNavigation) {
        Auth.resendSignUp(email).catch(error => {
          console.error("Error resending confirmation code:", error);
        });
      }
    }, [isUnconfirmedNavigation, email]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        if (name === 'email') setEmail(value);
        if (name === 'password') setPassword(value);
        if (name === 'confirmationCode') setConfirmationCode(value);
    };

    const handleSignUp = async event => {
        event.preventDefault();
        try {
            await Auth.signUp({
                username: email,
                password,
                attributes: { email }
            });
            await fetch(`${backendURL}/api/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    email,
                    marketing_opt: marketingOpt
                })
            });
            setStep(1);
        } catch (err) {
            setError(err.message);
        }
    };

    const handleConfirmation = async event => {
        event.preventDefault();
        try {
            await Auth.confirmSignUp(email, confirmationCode);
            setShowSuccessPopup(true);
            setTimeout(() => {
                navigate("/");
            }, 3000);
        } catch (err) {
            setError(err.message);
        }
    };

    const canSignUp = email && password && acceptTerms;

    return (
        <div className="auth-container">
            <div className="auth-box">
                {step === 0 ? (
                    <>
                        <h2>Create Account</h2>
                        <p>Please enter your email and desired password to create a new account.</p>
                        <form onSubmit={handleSignUp}>
                            <div className="input-group">
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={email}
                                    onChange={handleChange}
                                    className="auth-input"
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label>Password</label>
                                <input
                                    type="password"
                                    name="password"
                                    value={password}
                                    onChange={handleChange}
                                    className="auth-input"
                                    required
                                />
                            </div>

                            <div className="terms-container">
                                <h4>Terms and Conditions</h4>
                                <div className="terms-scroll">
                                    <h4>1. Introduction</h4>
                                    <p>Welcome to CPE Tracker, the foremost platform for tracking and understanding your Continuing Professional Education. We are dedicated to providing a seamless user experience. The ensuing terms and conditions ("Terms") provide the framework for the use of CPE Tracker's website, mobile application, and other affiliated platforms (collectively referred to as "Platform"). By accessing or using our Platform, you concur to be legally bound by these Terms. If you disagree, in part or whole, with these provisions, you should refrain from using our services.</p>

                                    <h4>1.1. Updates and Changes</h4>
                                    <p>The terms and conditions can undergo periodic updates. We advise users to frequently review this page to stay informed. Your continued use post any modifications indicates your acceptance of the revised terms.</p>

                                    <h4>2. Intellectual Property Rights & License</h4>
                                    <p>All content present on our Platform, including text, graphics, logos, and software, is the sole property of CPE Tracker or its content providers. This content is protected by copyright, trademarks, patents, and other applicable laws. Unless explicitly stated otherwise, CPE Tracker retains all rights to this content.</p>

                                    <h4>2.1. Usage Restrictions</h4>
                                    <p>You are permitted to view and download content from our Platform for personal, non-commercial purposes. This is the grant of a license, not a transfer of title. Under this license, you may not:
                                    <ul>
                                        <li>Modify or copy the materials;</li>
                                        <li>Use the materials for any commercial purpose or public display;</li>
                                        <li>Remove any copyright or proprietary notations;</li>
                                        <li>Transfer the materials to another person or mirror on another server.</li>
                                    </ul>
                                    </p>

                                    <h4>3. User Content & Conduct</h4>
                                    <p>When you share, post, or upload content to our Platform, you grant CPE Tracker a perpetual, non-exclusive, worldwide, royalty-free license to use, reproduce, modify, display, and distribute it. Please do not enter any sensitive information into the website.</p>

                                    <h4>3.1. Content Guidelines</h4>
                                    <p>All user-generated content must:
                                    <ul>
                                        <li>Be genuine and not intended to deceive;</li>
                                        <li>Not infringe any third-party rights, including copyright, trademark, or patent;</li>
                                        <li>Be free from discriminatory, hateful, or violent themes;</li>
                                        <li>Not contain any information that is unlawful or prohibited.</li>
                                    </ul>
                                    </p>

                                    <h4>3.2. Reporting Violations</h4>
                                    <p>If you come across any content that violates our terms, please report it immediately. We maintain the right, but not the obligation, to review, edit, or remove any content at our discretion.</p>

                                    <h4>4. Disclaimers & No Warranties</h4>
                                    <p>CPE Tracker endeavors to provide a high-quality experience but does not guarantee that our Platform will be error-free or uninterrupted, especially as it is still in beta. The Platform and its content are presented "as is" and "as available," without any warranties, either expressed or implied.</p>

                                    <h4>4.1. Limitations</h4>
                                    <p>Some jurisdictions do not allow exclusions of certain warranties or the exclusion of liability for incidental or consequential damages. Accordingly, some of the above limitations may not apply to you.</p>

                                    <h4>5. Limitation of Liability</h4>
                                    <p>Neither CPE Tracker nor any of its subsidiaries, affiliates, employees, directors, officers, or agents will be liable for any direct, indirect, incidental, special, or consequential damages resulting from your use or inability to use our Platform.</p>

                                    <h4>6. Governing Law & Jurisdiction</h4>
                                    <p>These terms and any related disputes are governed by the laws of the State of Australia. Any disputes or claims arising out of or in connection to these Terms will be subject to the exclusive jurisdiction of the state and federal courts of Australia.</p>

                                    <h4>6.1. Dispute Resolution</h4>
                                    <p>Before initiating any legal action, both parties agree to endeavor to resolve the dispute amicably through mutual discussions. If the dispute is not resolved within 30 days, either party may proceed with formal legal action.</p>
                                </div>
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        onChange={() => setAcceptTerms(!acceptTerms)}
                                        checked={acceptTerms}
                                    />
                                    <label>I accept the Terms and Conditions</label>
                                </div>
                                <div className="checkbox-container">
                                    <input
                                        type="checkbox"
                                        onChange={() => setMarketingOpt(!marketingOpt)}
                                        checked={marketingOpt}
                                    />
                                    <label>I would like to receive emails on new features and marketing from The CPE Tracker</label>
                                </div>
                            </div>
                            <button className="auth-btn" disabled={!canSignUp}>
                                Sign Up
                            </button>
                            <div className="sign-in-link">
                                Already have an account? <a href="/login" onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/login");
                                }}>Sign in Here</a>
                            </div>
                        </form>
                    </>
                ) : (
                    <>
                        <h2>Confirm Account</h2>
                        <p>{isUnconfirmedNavigation ? "You have not yet confirmed your account. We've resent a code to your email. Please enter it here." : "We've sent a code to your email. Please enter it here."}</p>
                        <form onSubmit={handleConfirmation}>
                            <div className="input-group">
                                <label>Confirmation Code</label>
                                <input
                                    type="text"
                                    name="confirmationCode"
                                    value={confirmationCode}
                                    onChange={handleChange}
                                    className="auth-input"
                                    required
                                />
                            </div>
                            <button className="auth-btn">Confirm</button>
                        </form>
                    </>
                )}

                {error && <div className="alert alert-danger">{error}</div>}
                {showSuccessPopup && <div className="alert alert-success">You have been registered successfully. Redirecting to login...</div>}
            </div>
        </div>
    );
}

export default SignUp;
