import React, { useState } from 'react';
import styles from './ContactUs.module.css';
import { useNavigate } from 'react-router-dom';
import Footer from './Footer';

const ContactUs = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://ofc6sny6qk.execute-api.ap-southeast-2.amazonaws.com/beta/submit', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.status === 200) {
                console.log("Email sent successfully");
                alert("Your message has been sent. Thank you!");
                setFormData({ name: '', email: '', message: '' }); // Reset form
                navigate('/')
            } else {
                // Log response status and text for debugging
                console.error(`Error sending email. Status: ${response.status}. Message: ${await response.text()}`);
                alert("There was a problem sending your message. Please try again later.");
            }
        } catch (error) {
            // Log the full error object for detailed debugging
            console.error("There was an error:", error);
            alert("There was a problem sending your message. Please try again later.");
        }
    };

    return (
      <div className="formParentContainer">
          <div className={styles.formContainer}>
              <h1 className={styles.formHeading}>Contact Us</h1>
              <p className={styles.formSubtext}>We love hearing from you! Please provide us any feedback, whether it be compliments, complaints, suggestions, or anything else!</p>
              <form onSubmit={handleSubmit}>
                  <label className={styles.label}>Name</label>
                  <input type="text" name="name" className={styles.inputField} onChange={handleChange} required />

                  <label className={styles.label}>Email</label>
                  <input type="email" name="email" className={styles.inputField} onChange={handleChange} required />

                  <label className={styles.label}>Your Message</label>
                  <textarea name="message" className={styles.textArea} onChange={handleChange} required />

                  <button className={styles.submitButton} type="submit">Submit</button>
                  <button className={styles.backButton} onClick={() => navigate('/')}>Back to Home</button>
              </form>
          </div>
          <Footer />
      </div>
    );
};

export default ContactUs;
