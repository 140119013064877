import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import linkedInLogo from './images/linkedin.png'; // Assuming you have the images in an 'images' directory
import twitterLogo from './images/twitter.png';
import facebookLogo from './images/facebook.png';

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="share-and-coffee-container">
                <iframe src="/coffee.html" frameBorder="0" scrolling="no" width="216" height="67" title="Buy Me a Coffee"></iframe>

                <div className="share-container">
                    <div className="share-prompt">
                        Enjoy using the site? Share it!
                    </div>
                    <div className="social-logos">
                        <a href="https://www.linkedin.com/sharing/share-offsite/?url=https%3A%2F%2Fthecpetracker.com" target="_blank" rel="noopener noreferrer">
                            <img src={linkedInLogo} alt="LinkedIn Logo" className="social-logo" />
                        </a>
                        <a href="https://twitter.com/intent/tweet?text=The%20CPE%20Tracker%3A%20The%20Foremost%20Solution%20For%20Tracking%20Your%20Certification%20CPEs&url=https%3A%2F%2Fthecpetracker.com%2F" target="_blank" rel="noopener noreferrer">
                            <img src={twitterLogo} alt="Twitter Logo" className="social-logo" />
                        </a>
                        <a href="https://www.facebook.com/dialog/share?href=https%3A%2F%2Fthecpetracker.com%2F&app_id=3452207431565225" target="_blank" rel="noopener noreferrer">
                            <img src={facebookLogo} alt="Facebook Logo" className="social-logo" />
                        </a>
                    </div>
                </div>
            </div>

            <div className="copyright-container">
                © 2023 The CPE Tracker. All Rights Reserved.
            </div>

            <div className="footer-links-container">
                <Link to="/termsandconditions">Terms and Conditions</Link>
                <Link to="/aboutus">About Us</Link>
                <Link to="/contactus">Contact Us</Link>
            </div>
        </footer>
    );
}

export default Footer;
