import React from 'react';
import { Button } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import './HomePage.css';
import UserContext from './UserContext';
import Header from './Header';
import Footer from './Footer';

class HomePage extends React.Component {
  state = {
    isAuthenticated: false
  };

  coffeeBtnContainer = React.createRef();

  async componentDidMount() {
    try {
      const user = await Auth.currentAuthenticatedUser();

      if (user) {
        const backendURL = "https://loc6yen3ke.execute-api.ap-southeast-2.amazonaws.com/prod";

        this.setState({ user, isAuthenticated: true });
        // Check if the user exists in the RDS database
        const response = await fetch(`${backendURL}/api/users/` + encodeURIComponent(user.attributes.email));
        if (!response.ok) {
            throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();

        // If user doesn't exist, add them to the database
        if (data == null || !data.email) {
            const newUser = {
                email: user.attributes.email
            };

            await fetch(`${backendURL}/api/users`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newUser)
            });
        }
      }

    } catch (e) {
      console.log(e);
    }
  }

  componentWillUnmount() {
    if (this.coffeeBtnContainer.current) {
      this.coffeeBtnContainer.current.innerHTML = ''; // Clear the innerHTML of the div on unmount
    }
  }

  handleLogout = async () => {
    try {
      await Auth.signOut();
      this.setState({ isAuthenticated: false, user: null });
    } catch (e) {
      console.log(e);
    }
  };

  render() {
    return (
      <UserContext.Provider value={this.state.user}>
      <div className="homepage">
           <Header
               isAuthenticated={this.state.isAuthenticated}
               user={this.state.user}
               handleLogout={this.handleLogout}
           />

          <div className="text-center mt-5">
            <h1>The CPE Tracker</h1>
            {this.state.isAuthenticated ? (
              <Button variant="primary" as={Link} to="/mycertifications">Track my CPEs</Button>
            ) : (
              <Button variant="primary" as={Link} to="/signup">Start Tracking Your CPEs Now!</Button>
            )}
            <div className="youtube-video-container">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/QJCMqmGF7Ac"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Embedded youtube"
              ></iframe>
            </div>
          </div>
          <Footer />
        </div>
      </UserContext.Provider>
    );
  }
}

export default HomePage;
