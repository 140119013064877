// Header.js

import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Header = ({ isAuthenticated, user, handleLogout }) => {
    return (
        <Navbar bg="light" expand="lg">
            <Navbar.Brand>The CPE Tracker</Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                <div className="mobile-auth-container">
                    <Nav className="auth-buttons">
                        {!isAuthenticated ? (
                            <>
                                <Nav.Item>
                                    <Button as={Link} to="/signup" variant="link" className="nav-btn">Sign Up</Button>
                                </Nav.Item>
                                <Nav.Item>
                                    <Button as={Link} to="/login" variant="link" className="nav-btn">Log In</Button>
                                </Nav.Item>
                            </>
                        ) : (
                            <>
                                <Nav.Item className="welcome-text">
                                    Welcome, <Link to="/">{user.attributes.email}</Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Button onClick={handleLogout} className="nav-btn">Log Out</Button>
                                </Nav.Item>
                            </>
                        )}
                    </Nav>
                </div>
            </Navbar.Collapse>
        </Navbar>
    );
}

export default Header;
