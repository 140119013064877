import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import './LogIn.css';

function LogIn() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [state, setState] = useState('login'); // 'resetPassword', 'enterCode', 'newPassword'
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');

  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      await Auth.signIn(email, password);
      navigate('/');
    } catch (error) {
      if (error.code === 'UserNotConfirmedException') {
        navigate('/signup', { state: { unconfirmed: true, userEmail: email } });
      } else {
        setError('Incorrect email or password.');
        console.error('Error signing in', error);
      }
    }
  };

  const handleForgotPassword = () => {
    setState('resetPassword');
  };

  const initiateResetPassword = async () => {
    try {
      await Auth.forgotPassword(email);
      setState('enterCode');
    } catch (error) {
      console.error('Error initiating password reset', error);
    }
  };

  const finalizeResetPassword = async () => {
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      setState('login');
      navigate('/');
    } catch (error) {
      console.error('Error finalizing password reset', error);
    }
  };

  return (
      <div className="login-container">
          <div className="login-box">
              {state === 'login' && (
                  <>
                      <h2>Welcome</h2>
                      <input
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="login-input"
                      />
                      <input
                          type="password"
                          placeholder="Password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          className="login-input"
                      />
                      {error && (
                        <div className="login-error">
                          {error}
                        </div>
                      )}
                      <button className="login-btn" onClick={handleLogin}>
                          Sign in
                      </button>
                      <button className="forgot-password" onClick={handleForgotPassword}>
                          Forgot Your Password?
                      </button>
                      <button className="signup-btn" onClick={() => navigate('/signup')}>
                          Create Account
                      </button>
                  </>
              )}

              {state === 'resetPassword' && (
                  <>
                      <h2>Reset Password</h2>
                      <p>Please enter your email address of your account. If your account exists, we will send you a reset code that you will need to use to reset your password.</p>
                      <input
                          type="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="login-input"
                      />
                      <button className="login-btn" onClick={initiateResetPassword}>
                          Send Reset Code
                      </button>
                  </>
              )}

              {state === 'enterCode' && (
                  <>
                      <h2>Change Password</h2>
                      <p>Enter the reset code we sent you in your email, and the new password you would like to use for your account.</p>
                      <input
                          type="text"
                          placeholder="Enter Code from Email"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                          className="login-input"
                      />
                      <input
                          type="password"
                          placeholder="New Password"
                          value={newPassword}
                          onChange={(e) => setNewPassword(e.target.value)}
                          className="login-input"
                      />
                      <button className="login-btn" onClick={finalizeResetPassword}>
                          Reset Password
                      </button>
                  </>
              )}
          </div>
      </div>
  );
}

export default LogIn;
