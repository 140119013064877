import React, { useState } from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { Auth } from 'aws-amplify';
import './AddCertification.css'; // Import the new CSS file
import { useNavigate } from 'react-router-dom';

function AddCertification() {
    const [name, setName] = useState('');
    const [earnedDate, setEarnedDate] = useState('');
    const [expiryDate, setExpiryDate] = useState('');
    const [verificationLink, setVerificationLink] = useState('');
    const backendURL = "https://loc6yen3ke.execute-api.ap-southeast-2.amazonaws.com/prod";
    const [cpeNeeded, setCpeNeeded] = useState('');
    const navigate = useNavigate();

    const handleCancel = () => {
        navigate('/mycertifications');
    };

    const handleSubmit = async () => {
        const authUser = await Auth.currentAuthenticatedUser();
        const userEmail = authUser.attributes.email;
        const userResponse = await fetch(`${backendURL}/api/users/${encodeURIComponent(userEmail)}`);
        const userData = await userResponse.json();

        if (userData.user_id) {
            try {
                const response = await fetch(`${backendURL}/api/certifications`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        certification_name: name,
                        user_id: userData.user_id,
                        cpe_needed: cpeNeeded || null,
                        earned_date: earnedDate,
                        expiry_date: expiryDate || null,
                        verification_link: verificationLink
                    })
                });

                if (!response.ok) {
                    console.error("Error adding certification:", await response.text());
                } else {
                    // Redirect to My Certifications page or any other page you prefer after adding a new cert.
                    window.location.href = "/mycertifications";
                }
            } catch (error) {
                console.error("Error adding certification:", error);
            }
        }
    }

    return (
        <Container className="add-certification-container">
            <h2>Add Certification</h2>
            <p>All fields marked with * are mandatory</p>
            <Form>
                <Form.Group as={Row} controlId="formName">
                    <Form.Label column sm="2">* Certification Name:</Form.Label>
                    <Col sm="10">
                        <Form.Control value={name} onChange={e => setName(e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formEarnedDate">
                    <Form.Label column sm="2">* Earned Date:</Form.Label>
                    <Col sm="10">
                        <Form.Control type="date" value={earnedDate} onChange={e => setEarnedDate(e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formCPE">
                    <Form.Label column sm="2">Number of CPEs Needed:</Form.Label>
                    <Col sm="10">
                        <Form.Control type="number" value={cpeNeeded} onChange={(e) => setCpeNeeded(e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formExpiryDate">
                    <Form.Label column sm="2">Expiry Date:</Form.Label>
                    <Col sm="10">
                        <Form.Control type="date" value={expiryDate} onChange={e => setExpiryDate(e.target.value)} />
                    </Col>
                </Form.Group>

                <Form.Group as={Row} controlId="formVerificationLink">
                    <Form.Label column sm="2">Verification Link:</Form.Label>
                    <Col sm="10">
                        <Form.Control type="url" value={verificationLink} onChange={e => setVerificationLink(e.target.value)} />
                    </Col>
                </Form.Group>

                <div className="button-group">
                    <Button onClick={handleSubmit}>Submit</Button>
                    <Button variant="outline-secondary" onClick={handleCancel} className="ml-2">Cancel</Button>
                </div>
            </Form>
        </Container>
    );
}

export default AddCertification;
