import React from 'react';
import { Link } from 'react-router-dom';
import './AboutUs.css';
import Footer from './Footer';

function AboutUs() {
  return (
    <div className="about-us">
      <div className="header">
        <h2>About Us</h2>
        <Link to="/" className="return-home">Return Home</Link>
      </div>
      <p>
        The CPE Tracker was born out of a genuine need. As professionals striving for continuous improvement, we understand the importance of keeping track of CPEs. Not only does it show our commitment to learning, but it's also essential for maintaining many industry certifications.
      </p>
      <p>
        Our platform is designed to simplify this tracking process while also providing an avenue for our users to share their progress, discuss recent learnings, and collaborate with peers. Whether you're a seasoned expert or just starting your journey, The CPE Tracker offers something valuable for everyone.
      </p>
      <h3>The Team</h3>
      <p>
        Behind The CPE Tracker is Renaldi Gondosubroto who share a common passion with many professionals for lifelong learning and development. My expertise in technology, education, and design has enabled us to craft a platform that's intuitive, user-friendly, and truly impactful.
      </p>
      <p>
        As we continue to grow and evolve, we're constantly seeking feedback from our user community. Together, we believe we can make professional development a seamless and rewarding experience for all.
      </p>
      <Footer />
    </div>
  );
}

export default AboutUs;
