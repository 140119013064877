import React, { useState, useEffect } from 'react';
import { Button, Container, Row, Col, Form } from 'react-bootstrap';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import './UpdateCertification.css';

function UpdateCertification() {
    const [certName, setCertName] = useState("");
    const [earnedDate, setEarnedDate] = useState("");
    const [expiryDate, setExpiryDate] = useState("");
    const [cpeNeeded, setCpeNeeded] = useState("");
    const [verificationLink, setVerificationLink] = useState("");
    const { certification_id } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const backendURL = "https://loc6yen3ke.execute-api.ap-southeast-2.amazonaws.com/prod";

    useEffect(() => {
        const fetchCertificationDetails = async () => {
            try {
                const user = await Auth.currentAuthenticatedUser();
                const response = await fetch(`${backendURL}/api/certification/${certification_id}`);
                const response2 = await fetch(`${backendURL}/api/users/${user.attributes.email}`);
                const data = await response.json();
                const data2 = await response2.json();
                const cert2 = data2.user_id;
                if(data && data.length > 0) {
                    const cert = data[0];
                    if (cert2 !== cert.user_id) {
                        navigate('/fourofourpage');
                        return;
                    }
                    setCertName(cert.certification_name);
                    setEarnedDate(formatDate(cert.earned_date)); // Format the date here
                    setExpiryDate(formatDate(cert.expiry_date)); // Format the date here
                    setCpeNeeded(cert.cpe_needed);
                    setVerificationLink(cert.verification_link || ''); // Set the verification link, or default to an empty string if it's not available.

                }
            } catch (error) {
                navigate('/fourofourpage');
                console.error("Error fetching certification:", error);
            }
        }
        fetchCertificationDetails();
    }, [certification_id]);


    const handleUpdate = async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const response3 = await fetch(`${backendURL}/api/certification/${certification_id}`);
            const response2 = await fetch(`${backendURL}/api/users/${user.attributes.email}`);
            const data3 = await response3.json();
            const data2 = await response2.json();
            const cert2 = data2.user_id;
            if(data3 && data3.length > 0) {
                const cert = data3[0];
                if (cert2 !== cert.user_id) {
                    navigate('/fourofourpage');
                    return;
                }
            }
            const response = await fetch(`${backendURL}/api/certifications/${certification_id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    certification_name: certName,
                    earned_date: earnedDate,
                    expiry_date: expiryDate,
                    cpe_needed: cpeNeeded,
                    verification_link: verificationLink
                })
            });
            const data = await response.json();
            // Navigate back to MyCertifications or show a success message
            navigate('/mycertifications');
        } catch (error) {

            console.error("Error updating certification:", error);
        }
    }

    return (
        <Container fluid className="update-container">
            <Row className="justify-content-md-center">
                <Col md="6">
                    <h2 className="update-title">Update Certification</h2>
                    <p className="mandatory-note">All fields marked with * are mandatory</p>
                    <Form>
                        <Form.Group controlId="certName">
                            <Form.Label>* Certification Name</Form.Label>
                            <Form.Control type="text" value={certName} onChange={e => setCertName(e.target.value)} placeholder="Enter certification name" />
                        </Form.Group>

                        <Form.Group controlId="earnedDate">
                            <Form.Label>* Earned Date</Form.Label>
                            <Form.Control type="date" value={earnedDate} onChange={e => setEarnedDate(e.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="expiryDate">
                            <Form.Label>Expiry Date</Form.Label>
                            <Form.Control type="date" value={expiryDate} onChange={e => setExpiryDate(e.target.value)} />
                        </Form.Group>

                        <Form.Group controlId="cpeNeeded">
                            <Form.Label>CPE Needed</Form.Label>
                            <Form.Control type="number" value={cpeNeeded} onChange={e => setCpeNeeded(e.target.value)} placeholder="Enter CPE needed" />
                        </Form.Group>

                        <Form.Group controlId="verificationLink">
                            <Form.Label>Verification Link</Form.Label>
                            <Form.Control type="url" value={verificationLink} onChange={e => setVerificationLink(e.target.value)} placeholder="Enter verification link" />
                        </Form.Group>

                        <Row>
                            <Col md={6}>
                                <Button variant="outline-primary" className="update-btn" onClick={handleUpdate}>Update</Button>
                            </Col>
                            <Col md={6}>
                                <Button variant="outline-secondary" className="cancel-btn" onClick={() => navigate('/mycertifications')}>Cancel</Button>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Container>
    );
}

function formatDate(isoString) {
    const date = new Date(isoString);
    let month = '' + (date.getUTCMonth() + 1); // getUTCMonth() is zero-based
    let day = '' + date.getUTCDate();
    const year = date.getUTCFullYear();

    if (month.length < 2)
        month = '0' + month;
    if (day.length < 2)
        day = '0' + day;

    return [year, month, day].join('-');
}

export default UpdateCertification;
